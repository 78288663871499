import React from 'react';
// import "../styles/Cell.css";

interface UpperSumsProps {
  filled: boolean[];
  score: number[];
}

const UpperSums = (props: UpperSumsProps) => {
  const { filled, score } = props;

  const getSum = (total: number, num: number) => {
    return total + num;
  };

  let subtotal = score.slice(0, 6).reduce(getSum);
  let complete = !filled.slice(0, 6).includes(false);
  let bonus = subtotal >= 63 ? 35 : 0;
  return (
    <>
      <div className="score-row">
        <div className="score-name">Subtotal</div>
        <div className="score-cell">
          <div className="score filled">{subtotal}</div>
        </div>
      </div>
      <div className="score-row">
        <div className="score-name">Bonus</div>
        <div className="score-cell">
          <div className="score filled">{bonus}</div>
        </div>
      </div>
      <div className="score-row">
        <div className="score-name">Total</div>
        <div className="score-cell">
          <div className="score filled">{subtotal + bonus}</div>
        </div>
      </div>
    </>
  );
};

export default UpperSums;
