import React from 'react';
// import '../styles/Cell.css';

interface CellProps {
  id: number;
  filled: boolean;
  score: number;
  potential: number;
  active: boolean;
  toggleCell: (id: number) => void;
}

const Cell = (props: CellProps) => {
  const { toggleCell, id, filled, score, potential, active } = props;
  const cellClick = () => {
    toggleCell(id);
  };
  let newScore = filled ? score : potential;

  let className = props.filled ? 'filled' : active ? 'active' : 'empty';
  return (
    <div className={`score-cell ${className}`}>
      <div className={`score ${className}`} onClick={cellClick}>
        {newScore}
      </div>
    </div>
  );
};

export default Cell;
