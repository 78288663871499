import React from 'react';
import { Link } from 'react-router-dom';
import { getAllGamesDocsData, lobbyLinkFromName } from '../../util/firebaseUtils';

interface ListGameProps {
  userId: string;
}

interface GameProps {
  id: string;
  name: string;
}

const ListGames = (props: ListGameProps) => {
  const [games, setGames] = React.useState([]);

  const fetchGames = async () => {
    const allGames = await getAllGamesDocsData();
    allGames && setGames(allGames);
  };

  React.useEffect(() => {
    fetchGames();
  }, []);

  return (
    <div>
      <h3>Join game</h3>
      <div>
        {games.map((game: GameProps) => {
          return (
            <div key={game.id}>
              <Link to={lobbyLinkFromName(game.name)}>{game.name}</Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListGames;
