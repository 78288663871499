import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { createUser } from '../../util/firebaseUtils';
import { LOGGED_IN_HOME_ROUTE_PATH } from '../game/constants';

const SignUp = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const createUserAndNavigateHome = async (userId: string) => {
    await createUser(userId, email, username);
    navigate(LOGGED_IN_HOME_ROUTE_PATH);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const userId = userCredential.user.uid;
        createUserAndNavigateHome(userId);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        //TODO: do something with error
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <div>
      <h1>Sign Up</h1>
      <form>
        <div>
          <input
            style={{ width: '200px', marginBottom: '1rem' }}
            type="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            placeholder="Username"
          />
        </div>

        <div>
          <input
            style={{ width: '200px', marginBottom: '1rem' }}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email address"
          />
        </div>

        <div>
          <input
            style={{ width: '200px', marginBottom: '1rem' }}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Password"
          />
        </div>

        <button style={{ width: '200px' }} type="submit" onClick={onSubmit}>
          Sign up
        </button>
      </form>

      <p>
        Already have an account? <NavLink to="/login">Login</NavLink>
      </p>
    </div>
  );
};

export default SignUp;
