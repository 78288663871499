import React from 'react';
import GameContainer from './GameContainer';
import Pusher from '../../Pusher';

const GameContainerWrapper = (props) => {
  const { userId } = props;
  return (
    <Pusher>
      <GameContainer userId={userId} />
    </Pusher>
  );
};

export default GameContainerWrapper;
