import React from 'react';
import { useParams } from 'react-router-dom';
import { getGameDocs, joinGame, exitGame, startGame } from '../../util/firebaseUtils';
import { useNavigate } from 'react-router-dom';

interface LobbyProps {
  userId: string;
  remoteGameStarted?: boolean;
  users?: string[];
}

const Lobby = (props: LobbyProps) => {
  const [gameId, setGameId] = React.useState();
  const [gameStarted, setGameStarted] = React.useState(false);
  const [playerIds, setPlayerIds] = React.useState([]);
  const [gameName, setGameName] = React.useState('');
  const [playerIsInGame, setPlayerIsInGame] = React.useState(false);
  const [currentPlayerId, setCurrentPlayerId] = React.useState(null);

  const params = useParams();
  const { userId, remoteGameStarted, users } = props;
  const navigate = useNavigate();
  const slug = params.slug;

  const fetchGame = async () => {
    if (slug) {
      const docs = await getGameDocs(slug);
      docs &&
        docs.forEach((doc) => {
          // only one game should be returned
          if (doc.data().endTime === '-1') {
            setGameId(doc.id);
            // @ts-ignore
            setGameStarted(doc.data().startTime ? true : false);
            setGameName(doc.data().name);
          }
        });
    }
  };

  const joinGameClick = async () => {
    if (gameId) {
      await joinGame(gameId, userId);
      setPlayerIds((playerIds) => [...playerIds, userId]);
    }
  };

  const exitGameClick = async () => {
    if (gameId) {
      gameId && (await exitGame(gameId, userId));
      setPlayerIds(playerIds.filter((item) => item !== userId));
      window.pusher.unsubscribe(gameId);
    }
  };

  const startGameClick = async () => {
    //set to the user that starts the game
    const currentPlayerId = playerIds[0];
    setCurrentPlayerId(currentPlayerId);
    gameId && (await startGame(gameId, currentPlayerId));
    setGameStarted(true);
  };

  React.useEffect(() => {
    fetchGame();
  }, []);

  React.useEffect(() => {
    if (gameId) {
    }
  }, [gameId]);

  React.useEffect(() => {
    userId && playerIds && setPlayerIsInGame(userId && playerIds && playerIds.includes(userId));
  }, [playerIds, userId]);

  React.useEffect(() => {
    if ((remoteGameStarted || gameStarted) && playerIsInGame) {
      navigate(`/game/${slug}`, {
        state: {
          gameId: gameId,
          userId: userId,
          gameName: gameName,
          // playerIds: playerIds,
          currentPlayerId: currentPlayerId || playerIds[0],
        },
      });
    }
  }, [gameStarted, playerIsInGame, remoteGameStarted]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
        <h1>{gameName}</h1>
        {/* <h1>{userId}</h1> */}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '80%',
        }}>
        <div>
          {gameId && !gameStarted && (
            <div style={{ width: '100%' }}>
              {!playerIsInGame && (
                <button disabled={playerIsInGame || gameStarted} onClick={joinGameClick}>
                  Join Game
                </button>
              )}

              {playerIsInGame && (
                <button disabled={!playerIsInGame} onClick={exitGameClick}>
                  Leave Game
                </button>
              )}

              <p>
                <button disabled={!playerIsInGame || gameStarted} onClick={startGameClick}>
                  Start Game
                </button>
              </p>
            </div>
          )}
        </div>
        <div>
          <h2 style={{ margin: 0 }}>Players</h2>
          {users && users.map((user) => <p key={user}>{user}</p>)}
        </div>
      </div>
    </>
  );
};

export default Lobby;
