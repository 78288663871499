import {
  setDoc,
  doc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  getCountFromServer,
  updateDoc,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import { FILLED_START_STATE, SCORE_START_STATE } from '../components/game/constants';

import { db } from '../services/firebase';

//https://firebase.google.com/docs/firestore/data-model#web-version-9_3

export const getGameDocs = async (slug: string) => {
  if (slug) {
    const game = query(collection(db, 'games'), where('slug', '==', slug));
    // @ts-ignore
    return game && (await getDocs(game));
  }
};

export const createUser = async (id: string, email: string, username: string) => {
  await setDoc(doc(db, `users/`, id), {
    email,
    username,
  });
};

export const getAllGamesDocsData = async () => {
  let games = [];
  await getDocs(collection(db, 'games')).then((querySnapshot) => {
    querySnapshot.docs.forEach((doc) => {
      if (doc.data().startTime || doc.data().endTime) {
        return {};
      }
      games.push({ ...doc.data(), id: doc.id });
    });
  });
  return games;
};

export const getGameDocsLengthFromName = async (name: string) => {
  let length = 0;
  const slug = createGameSlug(name);
  if (name) {
    const game = query(
      collection(db, 'games'),
      where('slug', '==', slug),
      where('endTime', '==', '-1')
    );
    // @ts-ignore
    const gamesRef = game && (await getCountFromServer(game));
    length = gamesRef && gamesRef.data().count;
  }
  return length;
};

export const createGameFromName = async (name: string) => {
  await addDoc(collection(db, 'games'), {
    name,
    slug: createGameSlug(name),
    endTime: '-1',
    currentPlayersScore: SCORE_START_STATE,
    currentPlayersFilled: FILLED_START_STATE,
  });
};

export const lobbyLinkFromName = (name: string) => {
  return `/lobby/${createGameSlug(name)}`;
};

export const createGameSlug = (name: string) => {
  if (name) {
    return name
      .replaceAll(/'/g, '')
      .replaceAll(/\W+/g, '-')
      .replace(/^[-]+|[-]+$/g, '')
      .toLowerCase();
  }
};

export const joinGame = async (gameId: string, userId: string) => {
  //update the user
  await setDoc(doc(db, `games/${gameId}/players/`, userId), {
    exitTime: null,
    score: SCORE_START_STATE,
    filled: FILLED_START_STATE,
  });

  //update the game
  const username = await getCurrentPlayerUsernameById(userId);
  await updateDoc(doc(db, 'games', gameId), {
    playerIds: arrayUnion(userId),
    users: arrayUnion(username),
    updatedAt: serverTimestamp(),
  });
};

export const exitGame = async (gameId: string, userId: string) => {
  //update the user
  await updateDoc(doc(db, `games/${gameId}/players/`, userId), {
    exitGame: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });

  //update the game
  const gamePlayers = await getPlayers(gameId);
  const username = await getCurrentPlayerUsernameById(userId);
  await updateDoc(doc(db, 'games', gameId), {
    playerIds: arrayRemove(userId),
    users: arrayRemove(username),
    updatedAt: serverTimestamp(),
    endTime: gamePlayers.length > 1 ? serverTimestamp() : null,
  });
};

export const startGame = async (gameId: string, currentPlayerId: string) => {
  const username = await getCurrentPlayerUsernameById(currentPlayerId);
  await updateDoc(doc(db, 'games', gameId), {
    startTime: serverTimestamp(),
    currentPlayerId: currentPlayerId,
    currentPlayerUsername: username,
    updatedAt: serverTimestamp(),
    // endTime: ,
  });
};

export const endGame = async (gameId: string) => {
  await updateDoc(doc(db, 'games', gameId), {
    endTime: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
};

export const getPlayers = async (gameId: string) => {
  const array = [];
  const players = collection(db, `games/${gameId}/players`);
  // @ts-ignore
  const docs = players && (await getDocs(players));
  docs &&
    // @ts-ignore
    docs.forEach((doc) => {
      if (!doc.data().exitTime) {
        array.push(doc.id);
      }
    });

  return array;
};

export const getCurrentPlayerId = async (gameId: string) => {
  const game = await getDoc(doc(db, `games`, gameId));
  return {
    currentPlayerId: game?.data()?.currentPlayerId,
    currentPlayerUsername: game?.data()?.currentPlayerUsername,
  };
};

export const getCurrentPlayerUsernameById = async (userId: string) => {
  const user = await getDoc(doc(db, `users`, userId));
  return user?.data()?.username;
};

export const setCurrentPlayerInfo = async (gameId: string, currentPlayerId: string) => {
  const username = await getCurrentPlayerUsernameById(currentPlayerId);
  await updateDoc(doc(db, 'games', gameId), {
    currentPlayerId: currentPlayerId,
    currentPlayerUsername: username,
  });
};

export const updateScoreOnServer = async (
  score: number[],
  filled: boolean[],
  gameId: string,
  userId: string
) => {
  await updateDoc(doc(db, `games/${gameId}/players/`, userId), { score: score, filled: filled });
  await updateDoc(doc(db, 'games', gameId), {
    updatedAt: serverTimestamp(),
    currentPlayersScore: score,
    currentPlayersFilled: filled,
  });
};
