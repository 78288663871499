export const ACTIVE_START_STATE = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];
export const POTENTIAL_START_STATE = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
export const FILLED_START_STATE = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];
export const SCORE_START_STATE = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

export const LOGGED_IN_HOME_ROUTE_PATH = '/home';
export const LOGGED_OUT_HOME_ROUTE = '/';
export const LOGIN_PATH = '/login';
