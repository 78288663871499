import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { NavLink } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const onResetPassword = (e: any) => {
    e.preventDefault();

    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage('Password reset email sent!');
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        const errorMessage = error.message;
        setMessage(errorMessage);
        // ..
      });
  };

  return (
    <div>
      <h1>Reset Password</h1>
      <form>
        <div>
          {!message && (
            <input
              style={{ width: '200px', marginBottom: '1rem' }}
              id="email-address"
              name="email"
              type="email"
              required
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
        </div>
        {message && (
          <div>
            <p>{message}</p>
          </div>
        )}
        <div>
          <button style={{ width: '200px' }} onClick={onResetPassword}>
            Reset Password
          </button>
        </div>
      </form>
      <p>
        No account yet? <NavLink to="/sign-up">Sign up</NavLink>
      </p>
      <p>
        <NavLink to="/login">Login</NavLink>
      </p>
    </div>
  );
};

export default Login;
