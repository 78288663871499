import React from 'react';

import one from '../images/one.png';
import one_hold from '../images/one_hold.png';
import two from '../images/two.png';
import two_hold from '../images/two_hold.png';
import three from '../images/three.png';
import three_hold from '../images/three_hold.png';
import four from '../images/four.png';
import four_hold from '../images/four_hold.png';
import five from '../images/five.png';
import five_hold from '../images/five_hold.png';
import six from '../images/six.png';
import six_hold from '../images/six_hold.png';
import blank from '../images/blank.png';

interface DiceProps {
  hold: boolean;
  pips: number;
  roll: number;
  id: number;
  toggleDiceHold: (id: number) => void;
}

const Dice = (props: DiceProps) => {
  const { pips, hold, roll, toggleDiceHold, id } = props;

  const holdDice = () => {
    toggleDiceHold(id);
  };

  const diceImages = [one, two, three, four, five, six];
  const diceImagesHold = [one_hold, two_hold, three_hold, four_hold, five_hold, six_hold];
  return (
    <>
      {roll === 0 ? (
        <img className="dice-image" src={blank} alt="empty die" />
      ) : (
        <img
          className="dice-image"
          src={hold ? diceImagesHold[pips] : diceImages[pips]}
          onClick={holdDice}
          alt="dice"
        />
      )}
    </>
  );
};
export default Dice;
