import React from 'react';
import LogoutButton from '../authentication/LogoutButton';
import ListGames from '../games/ListGames';
import CreateGame from '../games/CreateGame';

interface HomeProps {
  userId: string;
}

const Home = (props: HomeProps) => {
  const { userId } = props;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
        <h1>Yahtz.io</h1>
        {userId && <LogoutButton />}
      </div>
      {userId && (
        <div>
          <p>
            Welcome to Yahtz.io! You can either create a game or re-join a game you are currently
            in!
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <CreateGame />
            </div>
            <div style={{ width: '45%' }}>
              <ListGames userId={userId} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
