import React from 'react';
import { NavLink } from 'react-router-dom';

const HomeAuth = () => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
        <h1>Yahtz.io</h1>
      </div>
      <p>
        Already have an account? <NavLink to="/login">Login</NavLink>
      </p>
      <p>
        No account yet? <NavLink to="/sign-up">Sign up</NavLink>
      </p>
    </div>
  );
};

export default HomeAuth;
