import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { LOGGED_OUT_HOME_ROUTE } from '../game/constants';

const LogoutButton = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate(LOGGED_OUT_HOME_ROUTE);
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <button style={{ margin: 0, marginTop: '.5rem' }} onClick={handleLogout}>
      Logout
    </button>
  );
};

export default LogoutButton;
