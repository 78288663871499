import React from 'react';
import './App.css';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import Login from './components/authentication/Login';
import ResetPassword from './components/authentication/ResetPassword';
import SignUp from './components/authentication/SignUp';
import Home from './components/home/Home';
import HomeAuth from './components/home/HomeAuth';
import LobbyWrapper from './components/lobby/LobbyWrapper';
import GameContainerWrapper from './components/game/containers/GameContainerWrapper';
import { LOGGED_IN_HOME_ROUTE_PATH, LOGIN_PATH } from './components/game/constants';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './services/firebase';
import PusherJS from 'pusher-js';

const InternalRouter = () => {
  const [path, setPath] = React.useState('');
  const [redirect, setRedirect] = React.useState(false);
  const location = useLocation();
  const [userId, setUserId] = React.useState('');
  const pathname = location.pathname;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (userId) {
      window.pusher = new PusherJS('a9312987fc2947bb5514', {
        cluster: 'us2',
      });
      // Pusher.logToConsole = true;
    }
  }, [userId]);

  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
      if (!user) {
        setUserId('');
      }
    });
  }, []);

  const loggedOut = !userId && (pathname.includes('/lobby/') || pathname.includes('/game/'));

  React.useEffect(() => {
    setPath(pathname);
  }, []);

  React.useEffect(() => {
    // logged out and tried to go to game or lobby
    if (loggedOut) {
      setRedirect(true);
      navigate(LOGIN_PATH);
    }
    // we have saved the path and are redirect
    if (userId && redirect && (path.includes('/lobby/') || path.includes('/game/'))) {
      setRedirect(false);
      navigate(path);
    }
  }, [pathname]);

  return (
    <div style={{ margin: '0 4px' }}>
      <Routes>
        <Route path="/game/:slug" element={<GameContainerWrapper userId={userId} />} />
        <Route path="/lobby/:slug" element={<LobbyWrapper userId={userId} />} />
        <Route path="/home" element={<Home userId={userId} />} />

        {/*logged out routes*/}
        {userId ? (
          <Route path="/login" element={<Navigate to={LOGGED_IN_HOME_ROUTE_PATH} replace />} />
        ) : (
          <Route path="/login" element={<Login />} />
        )}
        {userId ? (
          <Route
            path="/reset-password"
            element={<Navigate to={LOGGED_IN_HOME_ROUTE_PATH} replace />}
          />
        ) : (
          <Route path="/reset-password" element={<ResetPassword />} />
        )}
        {userId ? (
          <Route path="/sign-up" element={<Navigate to={LOGGED_IN_HOME_ROUTE_PATH} replace />} />
        ) : (
          <Route path="/sign-up" element={<SignUp />} />
        )}
        {userId ? (
          <Route path="/" element={<Navigate to={LOGGED_IN_HOME_ROUTE_PATH} replace />} />
        ) : (
          <Route path="/" element={<HomeAuth user={userId} />} />
        )}
      </Routes>
    </div>
  );
};

export default InternalRouter;
