import React from 'react';

interface RollButtonProps {
  roll: number;
  handleRollClick: () => void;
  gameOver: boolean;
  isCurrentTurn: boolean;
  currentPlayerName: string;
}

const RollButton = (props: RollButtonProps) => {
  const { roll, handleRollClick, gameOver, isCurrentTurn, currentPlayerName } = props;
  return (
    <button
      id="roll-btn"
      disabled={!isCurrentTurn || gameOver}
      className={`roll-button${isCurrentTurn ? '' : ' not-current-turn'}`}
      onClick={handleRollClick}>
      {gameOver ? (
        'Game Over'
      ) : (
        <>
          {currentPlayerName + `'s Roll`}
          <br />
          {3 - roll + ` `}
          Rolls Remaining
        </>
      )}
    </button>
  );
};

export default RollButton;
