import React from 'react';
import Modal from '../ui/Modal';
import RollButton from '../ui/RollButton';
import Dice from '../ui/Dice';
import TableContainer from './TableContainer';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import {
  getCurrentPlayerId,
  setCurrentPlayerInfo,
  updateScoreOnServer,
  getPlayers,
  endGame,
  exitGame,
} from '../../../util/firebaseUtils';
import { useNavigate } from 'react-router-dom';
// import GameOver from '../ui/GameOver';
import { FILLED_START_STATE, SCORE_START_STATE } from '../constants';

const GameContainer = (props) => {
  const [endOfTurn, setEndOfTurn] = React.useState(false);
  const [roll, setRoll] = React.useState(0);
  const [pips, setPips] = React.useState([0, 0, 0, 0, 0]);
  const [hold, setHold] = React.useState([false, false, false, false, false]);
  const [rollClicked, setRollClicked] = React.useState(true);
  const [tableClicked, setTableClicked] = React.useState(false);
  const [gameOver, setGameOver] = React.useState(false);
  const [playerScoreFromServer, setPlayerScoreFromServer] = React.useState(SCORE_START_STATE);
  const [playerFilledFromServer, setPlayerFilledFromServer] = React.useState(FILLED_START_STATE);
  const [isCurrentPlayer, setIsCurrentPlayer] = React.useState(false);
  const [playerIds, setPlayerIds] = React.useState([]);
  const [currentPlayerName, setCurrentPlayerName] = React.useState('');
  const [resetGameTrigger, setResetGameTrigger] = React.useState(0);
  const [modalTrigger, setModalTrigger] = React.useState(false);

  const { currentPlayerId, currentPlayerUsername, gameId, gameName, userId } = props;
  const navigate = useNavigate();

  React.useEffect(() => {
    setCurrentPlayerName(currentPlayerUsername);
    setCurrentPlayerData();
  }, [props]);

  const gameOverClick = () => {
    setGameOver(true);
    gameId && endGame(gameId);
  };

  // (callback from Dice.js)
  const toggleDiceHold = (id: number) => {
    if (roll !== 0) {
      let holds = hold;
      holds[id] = !holds[id];
      setHold([...holds]);
    }
  };

  // (callback from TableContainer.js)
  const handleEndOfTurn = (score, filled) => {
    // setTableClicked(false);
    // setRollClicked(true);
    setEndOfTurn(true);
    updateScoreOnServer(score, filled, gameId, userId);
    advanceToNextPlayer();
    newTurn();
  };

  const advanceToNextPlayer = async () => {
    if (playerIds && playerIds.length > 1 && currentPlayerId) {
      const currentIndex = currentPlayerId && playerIds.indexOf(currentPlayerId);
      const nextIndex = (currentIndex + 1) % playerIds.length;
      const nextPlayerId = playerIds[nextIndex];
      gameId && (await setCurrentPlayerInfo(gameId, nextPlayerId));
      setCurrentPlayerData();
    }
  };

  const getPlayer = async () => {
    const player = await getDoc(doc(db, `games/${gameId}/players/`, userId));
    player && setPlayerScoreFromServer(player.data().score);
    player && setPlayerFilledFromServer(player.data().filled);
  };

  const setCurrentPlayerData = async () => {
    const currentPlayerFromDb = gameId && (await getCurrentPlayerId(gameId));
    setIsCurrentPlayer(userId === currentPlayerId);
    setCurrentPlayerName(currentPlayerFromDb?.currentPlayerUsername);
  };

  // (callback from RollButton.js)
  const handleRollClick = () => {
    if (isCurrentPlayer) {
      if (gameOver) {
        // newGame();
      } else if (roll != 3) {
        newRoll();
      }
    }
  };

  const newGame = () => {
    setRoll(0);
    setPips([0, 0, 0, 0, 0]);
    setHold([false, false, false, false, false]);
    setRollClicked(true);
    setTableClicked(false);
    setGameOver(false);
    setResetGameTrigger((resetGameTrigger) => resetGameTrigger + 1);
    //TODO: need to update a bunch of stuff on server like current players state
    // updateScoreOnServer(SCORE_START_STATE, FILLED_START_STATE, gameId, userId);
    // handleEndOfTurn(SCORE_START_STATE, FILLED_START_STATE);
  };

  const newTurn = () => {
    setEndOfTurn(false);
    setHold([false, false, false, false, false]);
    setRollClicked(true);
    setTableClicked(false);
    setRoll(0);
  };

  const newRoll = () => {
    const rollCount = roll;
    setRoll(rollCount + 1);
    rollDice();
  };

  const rollDice = (isNewTurn?: boolean) => {
    let currentHold = isNewTurn ? [false, false, false, false, false] : hold;
    let newPips = [...pips];
    for (let i = 0; i < 5; i++) {
      if (!currentHold[i]) {
        const num = Math.floor(Math.random() * 6);
        newPips[i] = num;
      }
    }
    setPips(newPips);
    const audio = new Audio('/dice_roll.mp3');
    audio.play();
    return newPips;
  };

  React.useEffect(() => {
    if (gameId) {
      getPlayer();
      getPlayers(gameId).then((players) => {
        setPlayerIds(players);
      });
    }
  }, [gameId]);

  const isSinglePlayer = playerIds?.length === 1;

  const isCurrentTurn = () => {
    let obj = {
      a: isSinglePlayer,
      b: endOfTurn,
      c: isCurrentPlayer,
    };
    return Object.values(obj).filter((e) => e === true).length >= 1;
  };

  const leaveGame = async () => {
    gameId && (await exitGame(gameId, userId));
    setPlayerIds(playerIds.filter((item) => item !== userId));
    window.pusher.unsubscribe(gameId);
    navigate('/');
  };

  const openModal = () => {
    setModalTrigger(true);
  };

  const closeModal = () => {
    setModalTrigger(false);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
        <div
          style={{
            // alignItems: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <h2 style={{ margin: 0 }}>{gameName}</h2>
        </div>
        <div>
          <Modal
            message={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <button style={{ margin: 0, marginBottom: 10 }} onClick={leaveGame}>
                  Leave Game
                </button>
                <button style={{ margin: 0, marginBottom: 10 }} onClick={newGame}>
                  Restart Game
                </button>
              </div>
            }
            modalTrigger={modalTrigger}
            closeModal={closeModal}
            // submitModal={submitModal}
            // message={modalMessage}
          />
          {/* <button style={{ margin: 0, marginRight: 8 }} onClick={leaveGame}>
            Leave Game
          </button> */}
          <button style={{ margin: 0, marginRight: 8 }} onClick={openModal}>
            Menu
          </button>
          {gameOver && (
            <button style={{ margin: 0 }} onClick={newGame}>
              New Game
            </button>
          )}
        </div>
      </div>
      <div className="dice-area">
        <TableContainer
          resetGameTrigger={resetGameTrigger}
          pips={pips}
          roll={roll}
          handleEndOfTurn={handleEndOfTurn}
          rollClicked={rollClicked}
          tableClicked={tableClicked}
          gameOverClick={gameOverClick}
          userId={userId}
          gameId={gameId}
          playerScoreFromServer={playerScoreFromServer}
          playerFilledFromServer={playerFilledFromServer}
          isSinglePlayer={isSinglePlayer}
        />
        <div className="dice-bar">
          <Dice id={0} pips={pips[0]} hold={hold[0]} toggleDiceHold={toggleDiceHold} roll={roll} />
          <Dice id={1} pips={pips[1]} hold={hold[1]} toggleDiceHold={toggleDiceHold} roll={roll} />
          <Dice id={2} pips={pips[2]} hold={hold[2]} toggleDiceHold={toggleDiceHold} roll={roll} />
          <Dice id={3} pips={pips[3]} hold={hold[3]} toggleDiceHold={toggleDiceHold} roll={roll} />
          <Dice id={4} pips={pips[4]} hold={hold[4]} toggleDiceHold={toggleDiceHold} roll={roll} />
        </div>
        <div className="roll-area">
          {/* <div className="roll-canvas"> */}
          {/* <GameOver gameOver={gameOver} /> */}
          {/* <p>{isCurrentPlayer ? 'Your Turn' : 'Someone else turn'}</p> */}
          <RollButton
            currentPlayerName={currentPlayerName}
            roll={roll}
            handleRollClick={handleRollClick}
            gameOver={gameOver}
            isCurrentTurn={isCurrentTurn()}
          />
          {/* <RollCount roll={roll} /> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default GameContainer;
