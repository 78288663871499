import React from 'react';
import './App.css';
import InternalRouter from './InternalRouter';
import { BrowserRouter as Router } from 'react-router-dom';

const App = () => {
  return (
    <React.StrictMode>
      <div className="main">
        <Router>
          <InternalRouter />
        </Router>
      </div>
    </React.StrictMode>
  );
};

export default App;
