import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getGameDocsLengthFromName,
  createGameFromName,
  lobbyLinkFromName,
} from '../../util/firebaseUtils';
import FormError from '../ui/FormError';
import _debounce from 'lodash/debounce';

const CreateGame = () => {
  const [error, setError] = useState('');
  const [value, setValue] = useState('');
  const navigate = useNavigate();

  const checkIfGameNameExists = async (inputValue: string) => {
    const gameLength = await getGameDocsLengthFromName(inputValue);
    if (gameLength > 0) {
      setError(`Game name ${inputValue} already exists`);
    } else {
      setError('');
    }
  };

  const handleDebounceFn = async (inputValue: string) => {
    checkIfGameNameExists(inputValue);
  };

  const debounceFn = React.useCallback(_debounce(handleDebounceFn, 1000), []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const gameLength = await getGameDocsLengthFromName(value);
    checkIfGameNameExists(value);
    if (gameLength === 0) {
      await createGameFromName(value);
      navigate(lobbyLinkFromName(value));
    }
  };

  function handleChange(event) {
    setValue(event.target.value);
    debounceFn(event.target.value);
  }

  return (
    <div>
      <h3>Create Game</h3>
      <form>
        <div style={{ display: 'flex' }}>
          <input
            type="text"
            value={value}
            // value={name}
            style={{ width: '200px', marginRight: '1rem' }}
            onChange={handleChange}
            required
            placeholder="Game Name"
          />
          <button type="submit" onClick={onSubmit} disabled={!value}>
            Go
          </button>
        </div>
        <FormError error={error} />
      </form>
    </div>
  );
};

export default CreateGame;
