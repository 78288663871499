import React from 'react';
import ReactDOM from 'react-dom';
// import "../styles/ScoreTable.css"

interface ModalProps {
  message: string;
  modalTrigger: boolean;
  closeModal?: () => void;
  submitModal?: () => void;
}

const Modal = (props: ModalProps) => {
  const { message, closeModal, submitModal, modalTrigger } = props;
  return modalTrigger
    ? ReactDOM.createPortal(
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-div" onClick={closeModal}>
            {message}
            {(closeModal || submitModal) && <hr />}
            <div style={{ display: 'flex', margin: '4px 20px' }}>
              {closeModal && <button onClick={closeModal}>Cancel</button>}
              {submitModal && <button onClick={submitModal}>Do It</button>}
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
};

export default Modal;
