import React from 'react';
// import '../styles/Cell.css';

interface LowerSumsProps {
  filled: boolean[];
  score: number[];
}

const LowerSums = (props: LowerSumsProps) => {
  const { filled, score } = props;

  const getSum = (total: number, num: number) => {
    return total + num;
  };

  let lowerSum = score.slice(6).reduce(getSum);
  let upperSubtotal = score.slice(0, 6).reduce(getSum);
  let upperSum = upperSubtotal >= 63 ? upperSubtotal + 35 : upperSubtotal;
  let upperComplete = !filled.slice(0, 6).includes(false);
  let lowerComplete = !filled.slice(6).includes(false);

  const className = upperComplete && lowerComplete ? ' filled' : ' empty';

  return (
    <div className="score-row">
      <div className="score-name">Grand Total</div>
      <div className={`score-cell ${className}`}>
        <div className={`score ${className}`}>{upperSum + lowerSum || `0`}</div>
      </div>
    </div>
  );
};

export default LowerSums;
