import React from 'react';

const FormError = (props) => {
  const { error } = props;
  return (
    <>
      {error && (
        <div className="error">
          <p>{error}</p>
        </div>
      )}
    </>
  );
};

export default FormError;
