import React from 'react';
import Lobby from './Lobby';
import Pusher from '../Pusher';

const LobbyWrapper = (props) => {
  const { userId } = props;
  return (
    <Pusher>
      <Lobby userId={userId} />
    </Pusher>
  );
};

export default LobbyWrapper;
