import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import FormError from '../ui/FormError';
import { LOGGED_IN_HOME_ROUTE_PATH } from '../game/constants';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const onLogin = (e: any) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        // const user = userCredential.user;
        navigate(LOGGED_IN_HOME_ROUTE_PATH);
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorMessage);
        // console.log(errorCode, errorMessage);
        // console.log(errorMessage);
      });
  };

  return (
    <div>
      <h1>Login</h1>
      <form>
        <div>
          {/* <label htmlFor="email-address">Email address</label> */}
          <input
            style={{ width: '200px', marginBottom: '1rem' }}
            id="email-address"
            name="email"
            type="email"
            required
            placeholder="Email address"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          {/* <label htmlFor="password">Password</label> */}
          <input
            style={{ width: '200px', marginBottom: '1rem' }}
            id="password"
            name="password"
            type="password"
            required
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <FormError error={error} />
        <div>
          <button style={{ width: '200px' }} onClick={onLogin}>
            Login
          </button>
        </div>
      </form>
      <p>
        No account yet? <NavLink to="/sign-up">Sign up</NavLink>
      </p>
      <p>
        <NavLink to="/reset-password">Reset Password</NavLink>
      </p>
    </div>
  );
};

export default Login;
