export const anyPairs = (whatDiceDoIHave) => {
  return Object.values(whatDiceDoIHave).includes(2);
};

export const anyTriples = (whatDiceDoIHave) => {
  return Object.values(whatDiceDoIHave).includes(3);
};

export const anyQuadruples = (whatDiceDoIHave) => {
  return Object.values(whatDiceDoIHave).includes(4);
};

export const anyYahtzees = (whatDiceDoIHave) => {
  return Object.values(whatDiceDoIHave).includes(5);
};

export const anyFourConsecutive = (whatDiceDoIHave) => {
  let sortedPips = Object.keys(whatDiceDoIHave);
  return (
    (sortedPips.length === 5 &&
      (sortedPips[4] - sortedPips[1] === 3 || sortedPips[3] - sortedPips[0] === 3)) ||
    (sortedPips.length === 4 && sortedPips[3] - sortedPips[0] === 3)
  );
};
export const anyFiveConsecutive = (whatDiceDoIHave) => {
  let sortedPips = Object.keys(whatDiceDoIHave);
  return sortedPips.length === 5 && sortedPips[4] - sortedPips[0] === 4;
};

export const UPPER_ITEMS: ItemProps[] = [
  { name: 'Ones', description: 'Total of all Ones', index: 0 },
  { name: 'Twos', description: 'Total of all Twos', index: 1 },
  { name: 'Threes', description: 'Total of all Threes', index: 2 },
  { name: 'Fours', description: 'Total of all Fours', index: 3 },
  { name: 'Fives', description: 'Total of all Fives', index: 4 },
  { name: 'Sixes', description: 'Total of all Sixes', index: 5 },
];

export const LOWER_ITEMS: ItemProps[] = [
  { name: '3 of Kind', description: 'Total of all Dice', index: 6 },
  { name: '4 of Kind', description: 'Total of all Dice', index: 7 },
  { name: 'Full', description: 'Score 25', index: 8 },
  { name: 'Small', description: 'Score 30', index: 9 },
  { name: 'Large', description: 'Score 40', index: 10 },
  { name: 'Chance', description: 'Total of All Dice', index: 12 },
  { name: 'Yatz', description: 'Score 50', index: 11 },
];
