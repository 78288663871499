import React from 'react';
import Cell from './Cell';
import { ItemProps } from '../containers/TableContainer';

interface RowsProps {
  active: boolean[];
  filled: boolean[];
  items: ItemProps[];
  potential: number[];
  score: number[];
  toggleCell: (id: number) => void;
}

const Rows = (props: RowsProps) => {
  const { items, active, potential, filled, score, toggleCell } = props;
  return items.map((item) => {
    return (
      <div className="score-row" key={item.index}>
        <div className="score-name">{item.name}</div>
        {/* {item.description}*/}
        <Cell
          id={item.index}
          active={active[item.index]}
          potential={potential[item.index]}
          filled={filled[item.index]}
          score={score[item.index]}
          toggleCell={toggleCell}
        />
      </div>
    );
  });
};

export default Rows;
