import React from 'react';

//@ts-ignore
const Bonus = (props) => {
  const className = props.yahtzees ? 'filled' : 'empty';
  const calculateBonus = () => {
    const yahtzees = props.yahtzees;
    if (yahtzees) {
      const num = yahtzees - 1 * 100;
      num.toString();
    } else {
      return '0';
    }
  };
  return (
    <div className="score-row">
      <div className="score-name">Yatz Bonus</div>
      <div className={`score-cell ${className}`}>
        <div className={`score ${className}`}>{calculateBonus()}</div>
      </div>
    </div>
  );
};

export default Bonus;
