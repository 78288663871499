import React from 'react';
import { useParams } from 'react-router-dom';
import { getGameDocs } from '../util/firebaseUtils';

//@ts-ignore
const Pusher = (props) => {
  const [gameId, setGameId] = React.useState('');
  const [gameName, setGameName] = React.useState('');
  const [gameData, setGameData] = React.useState(null);
  const [remoteGameStarted, setRemoteGameStarted] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [currentPlayerUsername, setCurrentPlayerUsername] = React.useState('');

  const params = useParams();
  const slug = params.slug;
  const [currentPlayerId, setCurrentPlayerId] = React.useState(null);

  React.useEffect(() => {
    console.log('gameData', gameData);
    const messageData = gameData?.message?.data;
    gameData && setCurrentPlayerId(messageData.currentPlayerId);
    gameData && setRemoteGameStarted(!!messageData.startTime);
    gameData && setUsers(messageData.users);
    gameData && setCurrentPlayerUsername(messageData.currentPlayerUsername);
  }, [gameData]);

  const fetchGame = async () => {
    if (slug) {
      const docs = await getGameDocs(slug);
      docs &&
        docs.forEach((doc) => {
          // only one game should be returned
          if (doc.data().endTime === '-1') {
            setGameId(doc.id);
            setGameName(doc.data().name);
            setCurrentPlayerId(doc.data().currentPlayerId);
            setCurrentPlayerUsername(doc.data().currentPlayerUsername);
          }
        });
    }
  };

  React.useEffect(() => {
    fetchGame();
  }, []);

  React.useEffect(() => {
    if (gameId) {
      const channel = window.pusher.subscribe(gameId);
      // @ts-ignore
      channel.bind('my-event', function (data) {
        setGameData(data);
      });
    }
  }, [gameId]);

  const renderChildren = () => {
    // React.cloneElement(props.children, { toggleProp: 'hello' });
    //https://sentry.io/answers/react-passing-props-to-children/#:~:text=To%20pass%20additional%20props%20to,returns%20a%20new%20React%20element.
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, {
        currentPlayerId,
        remoteGameStarted,
        users,
        currentPlayerUsername,
        gameId,
        gameName,
      });
    });
  };

  return <div className="app-container">{renderChildren()}</div>;
};

export default Pusher;
