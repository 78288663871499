/* eslint-disable*/
import React, { Component } from 'react';
import Rows from '../ui/Rows';
import UpperSums from '../ui/UpperSums';
import LowerSums from '../ui/LowerSums';
import Bonus from '../ui/Bonus';
import Modal from '../ui/Modal';
import {
  anyPairs,
  anyTriples,
  anyQuadruples,
  anyYahtzees,
  anyFourConsecutive,
  anyFiveConsecutive,
  UPPER_ITEMS,
  LOWER_ITEMS,
} from './Helpers';
import {
  ACTIVE_START_STATE,
  FILLED_START_STATE,
  POTENTIAL_START_STATE,
  SCORE_START_STATE,
} from '../constants';
import { updateScoreOnServer } from '../../../util/firebaseUtils';
// import '../styles/ScoreTable.css';

export interface ItemProps {
  description: string;
  index: number;
  name: string;
}

export interface TableContainerProps {
  pips: number[];
  roll: number;
  handleEndOfTurn: (score: any, filled: string) => void;
  rollClicked: boolean;
  tableClicked: boolean;
  gameOverClick: () => void;
  userId: string;
  gameId?: string;
  playerScoreFromServer: number[];
  playerFilledFromServer: boolean[];
  isSinglePlayer: boolean;
  resetGameTrigger: number;
}

const TableContainer = (props: TableContainerProps) => {
  const {
    roll,
    tableClicked,
    gameOverClick,
    gameId,
    pips,
    rollClicked,
    handleEndOfTurn,
    playerScoreFromServer,
    playerFilledFromServer,
    resetGameTrigger,
  } = props;
  const [active, setActive] = React.useState(ACTIVE_START_STATE);
  const [potential, setPotential] = React.useState(POTENTIAL_START_STATE);
  const [filled, setFilled] = React.useState(FILLED_START_STATE);
  const [score, setScore] = React.useState(SCORE_START_STATE);
  const [yahtzees, setYahtzees] = React.useState('');
  const [cell_id, setCellId] = React.useState(null);
  const [modalTrigger, setModalTrigger] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');

  const handleCellClick = (cellIdProp: number) => {
    if (cellIsClickable(cellIdProp)) {
      setCellId(cellIdProp);
      setModalMessageFunction(cellIdProp);
    }
  };

  const cellIsClickable = (cellIdProp: number) => {
    return roll !== 0 && !tableClicked && !filled[cellIdProp];
  };

  const setModalMessageFunction = (cellIdProp?: number) => {
    if (roll < 3) {
      let message = 'Are you sure you want to end your turn? You can still roll again.';
      cellIdProp &&
        potential[cellIdProp] === 0 &&
        (message += ' (This will result in a score of zero for this item.)');
      setModalMessage(message);
      openModal();
    } else if (cellIdProp && potential[cellIdProp] === 0) {
      setModalMessage('Are you sure you want to put a zero here?');
      openModal();
    } else {
      toggleCell(cellIdProp);
    }
  };

  const openModal = () => {
    setModalTrigger(true);
  };

  const closeModal = () => {
    setModalTrigger(false);
  };

  const submitModal = () => {
    setModalTrigger(false);
    toggleCell(cell_id);
  };

  const toggleCell = (cellIdProp?: number) => {
    let filledCopy = [...filled];
    let scoreCopy = [...score];
    if (rollClicked && cellIdProp !== null) {
      (filledCopy[cellIdProp] = true), (scoreCopy[cellIdProp] = potential[cellIdProp]);
    }
    setFilled(filledCopy);
    setScore(scoreCopy);
    handleEndOfTurn(scoreCopy, filledCopy);

    resetTable();
  };

  const resetTable = () => {
    setActive(ACTIVE_START_STATE);
    setPotential(POTENTIAL_START_STATE);
    setYahtzees('');
    setCellId(null);
    setModalTrigger(false);
    setModalMessage('');
  };

  const checkComplete = () => {
    const filledCopy = [...filled];
    filledCopy.pop();
    filledCopy.every((v) => v === true) && gameOverClick();
  };

  const organizeDice = () => {
    let whatDiceDoIHave = {};
    pips.forEach((dice) => {
      whatDiceDoIHave[dice] = (whatDiceDoIHave[dice] || 0) + 1;
    });
    activateLowerCells(whatDiceDoIHave);
  };

  const activateLowerCells = (whatDiceDoIHave) => {
    let newActive = [];
    newActive[6] =
      anyTriples(whatDiceDoIHave) || anyQuadruples(whatDiceDoIHave) || anyYahtzees(whatDiceDoIHave);
    newActive[7] = anyQuadruples(whatDiceDoIHave) || anyYahtzees(whatDiceDoIHave);
    newActive[8] = anyPairs(whatDiceDoIHave) && anyTriples(whatDiceDoIHave);
    newActive[9] = anyFourConsecutive(whatDiceDoIHave);
    newActive[10] = anyFiveConsecutive(whatDiceDoIHave);
    newActive[11] = anyYahtzees(whatDiceDoIHave);
    newActive[12] = true;
    if (anyYahtzees(whatDiceDoIHave)) {
      calculateYahtzeeBonus();
    }
    calculateLowerValues(newActive);
  };

  const calculateLowerValues = (newActive) => {
    let newPotential = [];
    let diceSum = pips.reduce((sum, pips) => sum + pips) + 5;
    newPotential[6] = newActive[6] ? diceSum : 0;
    newPotential[7] = newActive[7] ? diceSum : 0;
    newPotential[8] = newActive[8] ? 25 : 0;
    newPotential[9] = newActive[9] ? 30 : 0;
    newPotential[10] = newActive[10] ? 40 : 0;
    newPotential[11] = newActive[11] ? 50 : 0;
    newPotential[12] = diceSum;
    calculateUpperValues(newActive, newPotential);
  };

  const calculateUpperValues = (newActive, newPotential) => {
    for (let i = 0; i <= 5; i++) {
      let numberOfDice = pips.filter((x) => x === i).length;
      pips.includes(i)
        ? ((newActive[i] = true), (newPotential[i] = numberOfDice * (i + 1)))
        : ((newActive[i] = false), (newPotential[i] = 0));
    }
    updateTable(newActive, newPotential);
  };

  const calculateYahtzeeBonus = () => {
    if (filled[11]) {
      let bonus = yahtzees;
      let bonusScore = score;
      bonus += 'X';
      bonusScore[13] = bonus.length * 100;
      setYahtzees(bonus);
      setScore(bonusScore);
    }
  };

  const updateTable = (newActive, newPotential) => {
    setActive([...newActive]);
    setPotential([...newPotential]);
  };

  React.useEffect(() => {
    if (roll !== 0) {
      organizeDice();
    }
  }, [roll]);

  React.useEffect(() => {
    setFilled([...playerFilledFromServer]);
    setScore([...playerScoreFromServer]);
  }, [playerScoreFromServer, playerFilledFromServer, gameId]);

  React.useEffect(() => {
    checkComplete();
  }, [roll]);

  React.useEffect(() => {
    if (resetGameTrigger) {
      // new game reset score
      setFilled(FILLED_START_STATE);
      setScore(SCORE_START_STATE);
      setPotential(POTENTIAL_START_STATE);
      setActive(ACTIVE_START_STATE);
      setYahtzees('');
      gameId &&
        props.userId &&
        updateScoreOnServer(SCORE_START_STATE, FILLED_START_STATE, gameId, props.userId);
    }
  }, [resetGameTrigger]);

  return (
    <div className="scorecard-canvas">
      <Modal
        modalTrigger={modalTrigger}
        closeModal={closeModal}
        submitModal={submitModal}
        message={modalMessage}
      />
      <div
        style={{
          width: '50%',
        }}
        className="upper-scorecard">
        <div className="upper-table-body" style={{ marginRight: 2 }}>
          <Rows
            items={UPPER_ITEMS}
            active={active}
            potential={potential}
            filled={filled}
            score={score}
            toggleCell={handleCellClick}
          />
          <UpperSums score={score} filled={filled} />
        </div>
      </div>
      <div
        style={{
          width: '50%',
        }}
        className="lower-scorecard">
        <div className="lower-table-body">
          <Rows
            items={LOWER_ITEMS}
            active={active}
            potential={potential}
            filled={filled}
            score={score}
            toggleCell={handleCellClick}
          />
          <Bonus yahtzees={yahtzees} />
          <LowerSums score={score} filled={filled} />
        </div>
      </div>
    </div>
  );
};

export default TableContainer;
